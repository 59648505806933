// for reactivity in ssr try https://github.com/nuxt/nuxt.js/issues/2308#issuecomment-349526029
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { CustomerAccountSettings } from '@/shared/types/SettingsDict'

type Dict = { [key: string]: any }

type State = {
  'customer-account': CustomerAccountSettings | null
  _original: Dict
}

export const state = (): State => ({
  'customer-account': null,
  _original: {},
})

export const getters: GetterTree<State, State> = {
  getField,
}

export const mutations: MutationTree<State> = {
  updateField,
}

export const actions: ActionTree<State, State> = {}
