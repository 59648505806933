import { SettingsManager } from '@/shared/services/SettingsManager'

export function isInfoBoxDismissed(
  key: string,
  manager: SettingsManager
): boolean {
  const boxes = manager.get('dismissedInfoBoxes') ?? []

  return boxes.includes(key)
}

export function dismissInfoBox(key: string, manager: SettingsManager): void {
  if (!isInfoBoxDismissed(key, manager)) {
    const oldDismissedBoxes = manager.get('dismissedInfoBoxes') ?? []
    const newDismissedBoxes = [...oldDismissedBoxes, key]

    manager.set('dismissedInfoBoxes', newDismissedBoxes, true)
  }
}
