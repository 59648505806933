import { Context, Middleware } from '@nuxt/types'

/**
 * Request user if access token is present
 *
 * @param ctx
 */
const requiresAuthMiddleware: Middleware = async function (ctx: Context) {
  if (!ctx.$authService.account) {
    return ctx.redirect(
      ctx.app.localePath({
        name: 'login-confirm',
        query: {
          // target: ctx.route.fullPath
        },
      })
    )
  }
}

export default requiresAuthMiddleware
