import { Context } from '@nuxt/types'
import { handleError } from '@/shared/utils/handleError'

/**
 * Load all settings in parallel
 * @param context
 */
export default async function (context: Context) {
  try {
    const settings = [context.$customerAccountSettings]
    const promises = settings
      .filter((s) => !s.loaded)
      .map((s) => s.loadSettings())

    await Promise.all(promises)
  } catch (e) {
    handleError(context, e)
  }
}
