const middleware = {}

middleware['browserSupport'] = require('../middleware/browserSupport.ts')
middleware['browserSupport'] = middleware['browserSupport'].default || middleware['browserSupport']

middleware['preferredLocale'] = require('../middleware/preferredLocale.ts')
middleware['preferredLocale'] = middleware['preferredLocale'].default || middleware['preferredLocale']

middleware['requiresAuth'] = require('../middleware/requiresAuth.ts')
middleware['requiresAuth'] = middleware['requiresAuth'].default || middleware['requiresAuth']

middleware['settings'] = require('../middleware/settings.ts')
middleware['settings'] = middleware['settings'].default || middleware['settings']

export default middleware
