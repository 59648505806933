import { Context, Middleware } from '@nuxt/types'
import {
  localeExists,
  prefLocaleCookieName,
} from '@/shared/assets/i18n/i18nHelper'

/**
 * Request user if access token is present
 *
 * @param ctx
 */
const preferredLocaleMiddleware: Middleware = async function (ctx: Context) {
  const cookieName = ctx.$config.storagePrefix + prefLocaleCookieName
  const prefLocale = ctx.$cookies.get(cookieName) as string | undefined
  const activeLocale = ctx.app.i18n.locale
  const defaultLocale = ctx.app.i18n.defaultLocale

  if (ctx.$authService.account) {
    await ctx.app.i18n.setLocale(ctx.$authService.account.locale)
    return
  }

  if (prefLocale && prefLocale !== activeLocale) {
    await ctx.app.i18n.setLocale(prefLocale)
  }
}

export default preferredLocaleMiddleware
