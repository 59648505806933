import uxStore from '../../shared/store/uxStore'
import { RawLocation } from 'vue-router'

export type ContextNavigationItem = {
  key: string
  title: string
  i18n?: boolean
  route?: RawLocation
  prefix?: {
    icon: string
    iconColor?: string
    iconStyle?: string
    color?: string
    lazyIcon?: boolean
    imageUrl?: string | null
  }
}

const store = uxStore({
  state: {
    showNav: false as boolean,
    fabTimeout: 1500 as number,
    contextNavigationHeight: 60 as number,
    contextNavigationItem: null as ContextNavigationItem | null,
    backgroundImageUrl: null as string | null,
  },
  mutations: {
    setContextNavigationItem(state, item: ContextNavigationItem | null) {
      state.contextNavigationItem = item
    },
  },
  getters: {
    getContextNavigationItem: (state) => {
      return state.contextNavigationItem
    },
  },
})

export default store

export type UXState = ReturnType<typeof store['state']>
