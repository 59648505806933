
import Vue from 'vue'
import BaseLayout from '@/shared/layouts/BaseLayout.vue'
import { mapFields } from 'vuex-map-fields'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import { MetaInfo } from 'vue-meta'
import { UXState } from '@/bookingbuddy-shop/store/ux'
import { localeExists } from '@/shared/assets/i18n/i18nHelper'
import {
  dismissInfoBox,
  isInfoBoxDismissed,
} from '@/shared/utils/bookings/DissmessedInfoBoxesHelper'
import dayjs from 'dayjs'

const uxStoreFields = {
  footerHeight: 'footerHeight',
  sidebar: 'sidebar',
  bottomBar: 'bottomBar',
  topbar: 'topbar',
  statusBar: 'statusBar',
  colorTheme: 'colorTheme',
  showNav: 'showNav',
  fabTimeout: 'fabTimeout',
  bowser: 'bowser',
  contextNavigationItem: 'contextNavigationItem',
  backgroundImageUrl: 'backgroundImageUrl',
  isMobile: 'isMobile',
}

export default Vue.extend({
  name: 'AccountLayout',
  components: {
    BaseLayout,
  },
  middleware: ['browserSupport', 'preferredLocale', 'requiresAuth', 'settings'],
  data() {
    return {
      platform: this.$store.getters['platform/platform'](
        this.$jsonApiService
      ) as Platform | null,
      appNavigationPosition: 'left' as 'left' | 'bottom',
    }
  },
  head(): MetaInfo {
    const svgFaviconUrl = this.platform?.config.svgFaviconUrl
    const platformName = this.platform?.name ?? 'anny'
    let links: MetaInfo['link'] = []
    if (this.platform) {
      links = svgFaviconUrl
        ? [
            {
              rel: 'icon',
              type: 'image/svg+xm',
              href: svgFaviconUrl,
              sizes: 'any',
            },
          ]
        : []
    } else {
      links = [
        {
          rel: 'apple-touch-startup-image',
          hid: 'apple-touch-startup-image',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'apple-touch-icon',
          hid: 'apple-touch-icon',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'shortcut icon',
          href: '_favicons/favicon.ico',
          hid: 'shortcut icon',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-16x16.png',
          sizes: '16x16',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-32x32.png',
          sizes: '32x32',
        },
      ]
    }

    return {
      title: `${platformName} Account`,
      link: links,
    }
  },
  computed: {
    ...mapFields<typeof uxStoreFields, UXState>('ux', uxStoreFields),
    platformStyles(): string | null {
      return this.platform?.config?.styles ?? null
    },
    /**
     * Route when clicking on logo.
     */
    logoPath(): string {
      if (this.platform) {
        return this.localePath({
          name: 'explore',
        })
      }
      if (this.$authService.account) {
        return this.localePath({
          name: 'account',
        })
      }
      return this.localePath({
        name: 'checkout',
      })
    },
    lo(): string {
      return this.$i18n.locale
    },
  },
  watch: {
    ['$i18n.locale']: function (newValue, oldValue) {
      this.dismissInfoForDifferentLanguage()
    },
  },
  created() {
    // pre-determine mobile or non mobile based on useragent
    if (this.$authService.account) {
      if (this.isMobile) {
        this.showBottomAppNavigation()
      } else {
        this.showSidebarAppNavigation()
      }
    } else {
      this.hideAppNavigation()
    }
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    // check language
    await this.initStatusBarForLanguage()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // load community icons for switcher
    handleResize() {
      if (!this.$authService.account) {
        this.hideAppNavigation()
        return
      }
      const width = window.innerWidth
      // toggle sidebar based on window size
      if (width >= 768) {
        this.showSidebarAppNavigation()
      } else {
        this.showBottomAppNavigation()
      }
    },
    showSidebarAppNavigation() {
      this.appNavigationPosition = 'left'
      // show sidebar
      this.sidebar = {
        width: 70,
        hidden: false,
        overlay: false,
        iconWidth: 0,
      }
      // hide bottom bar
      this.bottomBar = {
        enabled: false,
        hidden: true,
        height: 0,
      }
    },
    showBottomAppNavigation() {
      this.appNavigationPosition = 'bottom'
      // hide sidebar
      this.sidebar = {
        width: 0,
        hidden: false,
        overlay: false,
        iconWidth: 0,
      }
      // show bottom bar
      this.bottomBar = {
        enabled: false,
        hidden: false,
        height: 70,
        hiddenMobile: this.bottomBar.hiddenMobile,
      }
    },
    hideAppNavigation() {
      // hide sidebar
      this.sidebar = {
        width: 0,
        hidden: false,
        overlay: false,
        iconWidth: 0,
      }
      // hide bottom bar
      this.bottomBar = {
        enabled: true,
        hidden: true,
        height: 0,
        hiddenMobile: this.bottomBar.hiddenMobile,
      }
    },
    async dismissInfoForDifferentLanguage() {
      const infoKey = 'teamsAppDifferentLanguage2'
      dismissInfoBox(infoKey, this.$customerAccountSettings)
      this.$ux.hideStatusBar()
    },
    async initStatusBarForLanguage() {
      if (this.$authService.account) {
        const teamsContext = await this.$teams.app.getContext()
        const teamsLocale = teamsContext.app.locale

        const infoKey = 'teamsAppDifferentLanguage2'

        if (
          teamsLocale &&
          !isInfoBoxDismissed(infoKey, this.$customerAccountSettings)
        ) {
          const parsedLocale = teamsLocale.split('-')[0]

          // check if locale exists, else fallback
          if (
            localeExists(parsedLocale) &&
            parsedLocale !== this.$authService.account.locale
          ) {
            this.$ux.showStatusBar({
              message: this.$i18n.t(
                'pages.layouts.weeklayout.changeLocale'
              ) as string,
              type: 'default',
              action: {
                handler: () => {
                  dayjs.locale(parsedLocale)
                  this.$i18n.setLocale(parsedLocale)
                  if (this.$authService.account) {
                    this.$authService.account.locale = parsedLocale
                    this.$authService.account.save()
                  }
                },
                text: this.$i18n.t(
                  'pages.layouts.weeklayout.changeNow'
                ) as string,
              },
              closeCallback: () => {
                this.dismissInfoForDifferentLanguage()
              },
            })
            return
          }
        }
      }
    },
  },
})
