import { Context, Plugin } from '@nuxt/types'
import * as MicrosoftTeams from '@microsoft/teams-js'
import { localeExists } from '@/shared/assets/i18n/i18nHelper'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $teams: typeof MicrosoftTeams
  }
  interface Context {
    $teams: typeof MicrosoftTeams
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $teams: typeof MicrosoftTeams
  }
}

const plugin: Plugin = async function (ctx: Context, inject) {
  try {
    await MicrosoftTeams.app.initialize()
  } catch (e) {
    // ignored
  }

  inject('teams', MicrosoftTeams)

  // check if we are in client
  if (process.client && !ctx.$authService.account) {
    const teamsContext = await ctx.$teams.app.getContext()
    const teamsLocale = teamsContext.app.locale

    if (teamsLocale) {
      const parsedLocale = teamsLocale.split('-')[0]

      // check if locale exists, else fallback
      if (localeExists(parsedLocale)) {
        await ctx.app.i18n.setLocale(parsedLocale)

        return
      }
    }
  }
}

export default plugin
