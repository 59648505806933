import { Context, Plugin } from '@nuxt/types'
import { initJsonApi, JsonApiService } from '@anny.co/vue-jsonapi-orm'
import { CustomerAccount } from '@/shared/jsonapi-orm/bookingbuddy/CustomerAccount'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Address } from '@/shared/jsonapi-orm/common/Address'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { Schedule } from '@/shared/jsonapi-orm/bookingbuddy/Schedule'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { PaymentMethod } from '@/shared/jsonapi-orm/payments/PaymentMethod'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { CustomForm } from '@/shared/jsonapi-orm/custom-fields/CustomForm'
import { CustomField } from '@/shared/jsonapi-orm/custom-fields/CustomField'
import { Payment } from '@/shared/jsonapi-orm/payments/Payment'
import { Favorite } from '@/shared/jsonapi-orm/bookingbuddy/Favorite'
import { CommunityAccount } from '@/shared/jsonapi-orm/bookingbuddy/CommunityAccount'
import { ResourceProperty } from '@/shared/jsonapi-orm/bookingbuddy/ResourceProperty'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'
import { ResourceMap } from '@/shared/jsonapi-orm/bookingbuddy/ResourceMap'
import { CancellationPolicy } from '@/shared/jsonapi-orm/bookingbuddy/CancellationPolicy'
import { ResourceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ResourceGroup'
import { LegalDocument } from '@/shared/jsonapi-orm/common/LegalDocument'
import { TestResult } from '@/shared/jsonapi-orm/tests/TestResult'
import { ServiceConfiguration } from '@/shared/jsonapi-orm/bookingbuddy/ServiceConfiguration'
import { ServiceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ServiceGroup'
import { Reminder } from '@/shared/jsonapi-orm/bookingbuddy/Reminder'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'
import { Item } from '@/shared/jsonapi-orm/accounting/Item'
import { Skill } from '@/shared/jsonapi-orm/bookingbuddy/Skill'
import { AccountSkill } from '@/shared/jsonapi-orm/bookingbuddy/AccountSkill'
import { BookingParticipant } from '@/shared/jsonapi-orm/bookingbuddy/BookingParticipant'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $jsonApiService: JsonApiService
  }
  interface Context {
    $jsonApiService: JsonApiService
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $jsonApiService: JsonApiService
  }
}

const plugin: Plugin = function (ctx: Context, inject) {
  const jsonApiService = new JsonApiService(ctx.store, undefined, true)
  initJsonApi(
    {
      service: jsonApiService,
      store: ctx.store,
      resources: [
        CustomerAccount,
        Resource,
        Service,
        Organization,
        Address,
        Community,
        Schedule,
        Order,
        Booking,
        BookingParticipant,
        Customer,
        PaymentMethod,
        ResourceMap,
        ResourceCategory,
        ResourceProperty,
        Property,
        Image,
        CustomForm,
        CustomField,
        Payment,
        Favorite,
        CommunityAccount,
        CancellationPolicy,
        ResourceGroup,
        LegalDocument,
        ServiceGroup,
        TestResult,
        ServiceConfiguration,
        Reminder,
        Invoice,
        Item,
        Skill,
        AccountSkill,
      ],
    },
    process.client
  )

  inject('jsonApiService', jsonApiService)
}

export default plugin
