import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _008ef755 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _c5fb4c92 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3402cbbf = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _4b4d30d1 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _e746ff90 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _409dcbd1 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _486cc766 = () => interopDefault(import('../pages/sentry/index.vue' /* webpackChunkName: "pages/sentry/index" */))
const _ecdd9890 = () => interopDefault(import('../pages/weekplaner/index.vue' /* webpackChunkName: "pages/weekplaner/index" */))
const _122685ec = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _599b2c96 = () => interopDefault(import('../pages/login/callback.vue' /* webpackChunkName: "pages/login/callback" */))
const _9fa76c02 = () => interopDefault(import('../pages/login/confirm.vue' /* webpackChunkName: "pages/login/confirm" */))
const _13a860ad = () => interopDefault(import('../pages/login/redirect.vue' /* webpackChunkName: "pages/login/redirect" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _008ef755,
    name: "account___de___default"
  }, {
    path: "/cs",
    component: _c5fb4c92,
    name: "index___cs"
  }, {
    path: "/de",
    component: _c5fb4c92,
    name: "index___de"
  }, {
    path: "/de-formal",
    component: _c5fb4c92,
    name: "index___de-formal"
  }, {
    path: "/en",
    component: _c5fb4c92,
    name: "index___en"
  }, {
    path: "/en-us",
    component: _c5fb4c92,
    name: "index___en-us"
  }, {
    path: "/es",
    component: _c5fb4c92,
    name: "index___es"
  }, {
    path: "/favorites",
    component: _3402cbbf,
    name: "favorites___de___default"
  }, {
    path: "/fr",
    component: _c5fb4c92,
    name: "index___fr"
  }, {
    path: "/hu",
    component: _c5fb4c92,
    name: "index___hu"
  }, {
    path: "/it",
    component: _c5fb4c92,
    name: "index___it"
  }, {
    path: "/login",
    component: _4b4d30d1,
    name: "login___de___default"
  }, {
    path: "/logout",
    component: _e746ff90,
    name: "logout___de___default"
  }, {
    path: "/nb",
    component: _c5fb4c92,
    name: "index___nb"
  }, {
    path: "/nl",
    component: _c5fb4c92,
    name: "index___nl"
  }, {
    path: "/pl",
    component: _c5fb4c92,
    name: "index___pl"
  }, {
    path: "/profile",
    component: _409dcbd1,
    name: "profile___de___default"
  }, {
    path: "/pt",
    component: _c5fb4c92,
    name: "index___pt"
  }, {
    path: "/ro",
    component: _c5fb4c92,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _c5fb4c92,
    name: "index___ru"
  }, {
    path: "/sentry",
    component: _486cc766,
    name: "sentry___de___default"
  }, {
    path: "/tr",
    component: _c5fb4c92,
    name: "index___tr"
  }, {
    path: "/ua",
    component: _c5fb4c92,
    name: "index___ua"
  }, {
    path: "/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___de___default"
  }, {
    path: "/account/profile",
    component: _122685ec,
    name: "account-profile___de___default"
  }, {
    path: "/cs/account",
    component: _008ef755,
    name: "account___cs"
  }, {
    path: "/cs/favorites",
    component: _3402cbbf,
    name: "favorites___cs"
  }, {
    path: "/cs/login",
    component: _4b4d30d1,
    name: "login___cs"
  }, {
    path: "/cs/logout",
    component: _e746ff90,
    name: "logout___cs"
  }, {
    path: "/cs/profile",
    component: _409dcbd1,
    name: "profile___cs"
  }, {
    path: "/cs/sentry",
    component: _486cc766,
    name: "sentry___cs"
  }, {
    path: "/cs/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___cs"
  }, {
    path: "/de-formal/account",
    component: _008ef755,
    name: "account___de-formal"
  }, {
    path: "/de-formal/favorites",
    component: _3402cbbf,
    name: "favorites___de-formal"
  }, {
    path: "/de-formal/login",
    component: _4b4d30d1,
    name: "login___de-formal"
  }, {
    path: "/de-formal/logout",
    component: _e746ff90,
    name: "logout___de-formal"
  }, {
    path: "/de-formal/profile",
    component: _409dcbd1,
    name: "profile___de-formal"
  }, {
    path: "/de-formal/sentry",
    component: _486cc766,
    name: "sentry___de-formal"
  }, {
    path: "/de-formal/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___de-formal"
  }, {
    path: "/de/account",
    component: _008ef755,
    name: "account___de"
  }, {
    path: "/de/favorites",
    component: _3402cbbf,
    name: "favorites___de"
  }, {
    path: "/de/login",
    component: _4b4d30d1,
    name: "login___de"
  }, {
    path: "/de/logout",
    component: _e746ff90,
    name: "logout___de"
  }, {
    path: "/de/profile",
    component: _409dcbd1,
    name: "profile___de"
  }, {
    path: "/de/sentry",
    component: _486cc766,
    name: "sentry___de"
  }, {
    path: "/de/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___de"
  }, {
    path: "/en-us/account",
    component: _008ef755,
    name: "account___en-us"
  }, {
    path: "/en-us/favorites",
    component: _3402cbbf,
    name: "favorites___en-us"
  }, {
    path: "/en-us/login",
    component: _4b4d30d1,
    name: "login___en-us"
  }, {
    path: "/en-us/logout",
    component: _e746ff90,
    name: "logout___en-us"
  }, {
    path: "/en-us/profile",
    component: _409dcbd1,
    name: "profile___en-us"
  }, {
    path: "/en-us/sentry",
    component: _486cc766,
    name: "sentry___en-us"
  }, {
    path: "/en-us/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___en-us"
  }, {
    path: "/en/account",
    component: _008ef755,
    name: "account___en"
  }, {
    path: "/en/favorites",
    component: _3402cbbf,
    name: "favorites___en"
  }, {
    path: "/en/login",
    component: _4b4d30d1,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _e746ff90,
    name: "logout___en"
  }, {
    path: "/en/profile",
    component: _409dcbd1,
    name: "profile___en"
  }, {
    path: "/en/sentry",
    component: _486cc766,
    name: "sentry___en"
  }, {
    path: "/en/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___en"
  }, {
    path: "/es/account",
    component: _008ef755,
    name: "account___es"
  }, {
    path: "/es/favorites",
    component: _3402cbbf,
    name: "favorites___es"
  }, {
    path: "/es/login",
    component: _4b4d30d1,
    name: "login___es"
  }, {
    path: "/es/logout",
    component: _e746ff90,
    name: "logout___es"
  }, {
    path: "/es/profile",
    component: _409dcbd1,
    name: "profile___es"
  }, {
    path: "/es/sentry",
    component: _486cc766,
    name: "sentry___es"
  }, {
    path: "/es/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___es"
  }, {
    path: "/fr/account",
    component: _008ef755,
    name: "account___fr"
  }, {
    path: "/fr/favorites",
    component: _3402cbbf,
    name: "favorites___fr"
  }, {
    path: "/fr/login",
    component: _4b4d30d1,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _e746ff90,
    name: "logout___fr"
  }, {
    path: "/fr/profile",
    component: _409dcbd1,
    name: "profile___fr"
  }, {
    path: "/fr/sentry",
    component: _486cc766,
    name: "sentry___fr"
  }, {
    path: "/fr/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___fr"
  }, {
    path: "/hu/account",
    component: _008ef755,
    name: "account___hu"
  }, {
    path: "/hu/favorites",
    component: _3402cbbf,
    name: "favorites___hu"
  }, {
    path: "/hu/login",
    component: _4b4d30d1,
    name: "login___hu"
  }, {
    path: "/hu/logout",
    component: _e746ff90,
    name: "logout___hu"
  }, {
    path: "/hu/profile",
    component: _409dcbd1,
    name: "profile___hu"
  }, {
    path: "/hu/sentry",
    component: _486cc766,
    name: "sentry___hu"
  }, {
    path: "/hu/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___hu"
  }, {
    path: "/it/account",
    component: _008ef755,
    name: "account___it"
  }, {
    path: "/it/favorites",
    component: _3402cbbf,
    name: "favorites___it"
  }, {
    path: "/it/login",
    component: _4b4d30d1,
    name: "login___it"
  }, {
    path: "/it/logout",
    component: _e746ff90,
    name: "logout___it"
  }, {
    path: "/it/profile",
    component: _409dcbd1,
    name: "profile___it"
  }, {
    path: "/it/sentry",
    component: _486cc766,
    name: "sentry___it"
  }, {
    path: "/it/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___it"
  }, {
    path: "/login/callback",
    component: _599b2c96,
    name: "login-callback___de___default"
  }, {
    path: "/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___de___default"
  }, {
    path: "/login/redirect",
    component: _13a860ad,
    name: "login-redirect___de___default"
  }, {
    path: "/nb/account",
    component: _008ef755,
    name: "account___nb"
  }, {
    path: "/nb/favorites",
    component: _3402cbbf,
    name: "favorites___nb"
  }, {
    path: "/nb/login",
    component: _4b4d30d1,
    name: "login___nb"
  }, {
    path: "/nb/logout",
    component: _e746ff90,
    name: "logout___nb"
  }, {
    path: "/nb/profile",
    component: _409dcbd1,
    name: "profile___nb"
  }, {
    path: "/nb/sentry",
    component: _486cc766,
    name: "sentry___nb"
  }, {
    path: "/nb/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___nb"
  }, {
    path: "/nl/account",
    component: _008ef755,
    name: "account___nl"
  }, {
    path: "/nl/favorites",
    component: _3402cbbf,
    name: "favorites___nl"
  }, {
    path: "/nl/login",
    component: _4b4d30d1,
    name: "login___nl"
  }, {
    path: "/nl/logout",
    component: _e746ff90,
    name: "logout___nl"
  }, {
    path: "/nl/profile",
    component: _409dcbd1,
    name: "profile___nl"
  }, {
    path: "/nl/sentry",
    component: _486cc766,
    name: "sentry___nl"
  }, {
    path: "/nl/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___nl"
  }, {
    path: "/pl/account",
    component: _008ef755,
    name: "account___pl"
  }, {
    path: "/pl/favorites",
    component: _3402cbbf,
    name: "favorites___pl"
  }, {
    path: "/pl/login",
    component: _4b4d30d1,
    name: "login___pl"
  }, {
    path: "/pl/logout",
    component: _e746ff90,
    name: "logout___pl"
  }, {
    path: "/pl/profile",
    component: _409dcbd1,
    name: "profile___pl"
  }, {
    path: "/pl/sentry",
    component: _486cc766,
    name: "sentry___pl"
  }, {
    path: "/pl/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___pl"
  }, {
    path: "/pt/account",
    component: _008ef755,
    name: "account___pt"
  }, {
    path: "/pt/favorites",
    component: _3402cbbf,
    name: "favorites___pt"
  }, {
    path: "/pt/login",
    component: _4b4d30d1,
    name: "login___pt"
  }, {
    path: "/pt/logout",
    component: _e746ff90,
    name: "logout___pt"
  }, {
    path: "/pt/profile",
    component: _409dcbd1,
    name: "profile___pt"
  }, {
    path: "/pt/sentry",
    component: _486cc766,
    name: "sentry___pt"
  }, {
    path: "/pt/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___pt"
  }, {
    path: "/ro/account",
    component: _008ef755,
    name: "account___ro"
  }, {
    path: "/ro/favorites",
    component: _3402cbbf,
    name: "favorites___ro"
  }, {
    path: "/ro/login",
    component: _4b4d30d1,
    name: "login___ro"
  }, {
    path: "/ro/logout",
    component: _e746ff90,
    name: "logout___ro"
  }, {
    path: "/ro/profile",
    component: _409dcbd1,
    name: "profile___ro"
  }, {
    path: "/ro/sentry",
    component: _486cc766,
    name: "sentry___ro"
  }, {
    path: "/ro/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___ro"
  }, {
    path: "/ru/account",
    component: _008ef755,
    name: "account___ru"
  }, {
    path: "/ru/favorites",
    component: _3402cbbf,
    name: "favorites___ru"
  }, {
    path: "/ru/login",
    component: _4b4d30d1,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _e746ff90,
    name: "logout___ru"
  }, {
    path: "/ru/profile",
    component: _409dcbd1,
    name: "profile___ru"
  }, {
    path: "/ru/sentry",
    component: _486cc766,
    name: "sentry___ru"
  }, {
    path: "/ru/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___ru"
  }, {
    path: "/tr/account",
    component: _008ef755,
    name: "account___tr"
  }, {
    path: "/tr/favorites",
    component: _3402cbbf,
    name: "favorites___tr"
  }, {
    path: "/tr/login",
    component: _4b4d30d1,
    name: "login___tr"
  }, {
    path: "/tr/logout",
    component: _e746ff90,
    name: "logout___tr"
  }, {
    path: "/tr/profile",
    component: _409dcbd1,
    name: "profile___tr"
  }, {
    path: "/tr/sentry",
    component: _486cc766,
    name: "sentry___tr"
  }, {
    path: "/tr/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___tr"
  }, {
    path: "/ua/account",
    component: _008ef755,
    name: "account___ua"
  }, {
    path: "/ua/favorites",
    component: _3402cbbf,
    name: "favorites___ua"
  }, {
    path: "/ua/login",
    component: _4b4d30d1,
    name: "login___ua"
  }, {
    path: "/ua/logout",
    component: _e746ff90,
    name: "logout___ua"
  }, {
    path: "/ua/profile",
    component: _409dcbd1,
    name: "profile___ua"
  }, {
    path: "/ua/sentry",
    component: _486cc766,
    name: "sentry___ua"
  }, {
    path: "/ua/weekplaner",
    component: _ecdd9890,
    name: "weekplaner___ua"
  }, {
    path: "/cs/account/profile",
    component: _122685ec,
    name: "account-profile___cs"
  }, {
    path: "/cs/login/callback",
    component: _599b2c96,
    name: "login-callback___cs"
  }, {
    path: "/cs/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___cs"
  }, {
    path: "/cs/login/redirect",
    component: _13a860ad,
    name: "login-redirect___cs"
  }, {
    path: "/de-formal/account/profile",
    component: _122685ec,
    name: "account-profile___de-formal"
  }, {
    path: "/de-formal/login/callback",
    component: _599b2c96,
    name: "login-callback___de-formal"
  }, {
    path: "/de-formal/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___de-formal"
  }, {
    path: "/de-formal/login/redirect",
    component: _13a860ad,
    name: "login-redirect___de-formal"
  }, {
    path: "/de/account/profile",
    component: _122685ec,
    name: "account-profile___de"
  }, {
    path: "/de/login/callback",
    component: _599b2c96,
    name: "login-callback___de"
  }, {
    path: "/de/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___de"
  }, {
    path: "/de/login/redirect",
    component: _13a860ad,
    name: "login-redirect___de"
  }, {
    path: "/en-us/account/profile",
    component: _122685ec,
    name: "account-profile___en-us"
  }, {
    path: "/en-us/login/callback",
    component: _599b2c96,
    name: "login-callback___en-us"
  }, {
    path: "/en-us/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___en-us"
  }, {
    path: "/en-us/login/redirect",
    component: _13a860ad,
    name: "login-redirect___en-us"
  }, {
    path: "/en/account/profile",
    component: _122685ec,
    name: "account-profile___en"
  }, {
    path: "/en/login/callback",
    component: _599b2c96,
    name: "login-callback___en"
  }, {
    path: "/en/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___en"
  }, {
    path: "/en/login/redirect",
    component: _13a860ad,
    name: "login-redirect___en"
  }, {
    path: "/es/account/profile",
    component: _122685ec,
    name: "account-profile___es"
  }, {
    path: "/es/login/callback",
    component: _599b2c96,
    name: "login-callback___es"
  }, {
    path: "/es/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___es"
  }, {
    path: "/es/login/redirect",
    component: _13a860ad,
    name: "login-redirect___es"
  }, {
    path: "/fr/account/profile",
    component: _122685ec,
    name: "account-profile___fr"
  }, {
    path: "/fr/login/callback",
    component: _599b2c96,
    name: "login-callback___fr"
  }, {
    path: "/fr/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___fr"
  }, {
    path: "/fr/login/redirect",
    component: _13a860ad,
    name: "login-redirect___fr"
  }, {
    path: "/hu/account/profile",
    component: _122685ec,
    name: "account-profile___hu"
  }, {
    path: "/hu/login/callback",
    component: _599b2c96,
    name: "login-callback___hu"
  }, {
    path: "/hu/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___hu"
  }, {
    path: "/hu/login/redirect",
    component: _13a860ad,
    name: "login-redirect___hu"
  }, {
    path: "/it/account/profile",
    component: _122685ec,
    name: "account-profile___it"
  }, {
    path: "/it/login/callback",
    component: _599b2c96,
    name: "login-callback___it"
  }, {
    path: "/it/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___it"
  }, {
    path: "/it/login/redirect",
    component: _13a860ad,
    name: "login-redirect___it"
  }, {
    path: "/nb/account/profile",
    component: _122685ec,
    name: "account-profile___nb"
  }, {
    path: "/nb/login/callback",
    component: _599b2c96,
    name: "login-callback___nb"
  }, {
    path: "/nb/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___nb"
  }, {
    path: "/nb/login/redirect",
    component: _13a860ad,
    name: "login-redirect___nb"
  }, {
    path: "/nl/account/profile",
    component: _122685ec,
    name: "account-profile___nl"
  }, {
    path: "/nl/login/callback",
    component: _599b2c96,
    name: "login-callback___nl"
  }, {
    path: "/nl/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___nl"
  }, {
    path: "/nl/login/redirect",
    component: _13a860ad,
    name: "login-redirect___nl"
  }, {
    path: "/pl/account/profile",
    component: _122685ec,
    name: "account-profile___pl"
  }, {
    path: "/pl/login/callback",
    component: _599b2c96,
    name: "login-callback___pl"
  }, {
    path: "/pl/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___pl"
  }, {
    path: "/pl/login/redirect",
    component: _13a860ad,
    name: "login-redirect___pl"
  }, {
    path: "/pt/account/profile",
    component: _122685ec,
    name: "account-profile___pt"
  }, {
    path: "/pt/login/callback",
    component: _599b2c96,
    name: "login-callback___pt"
  }, {
    path: "/pt/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___pt"
  }, {
    path: "/pt/login/redirect",
    component: _13a860ad,
    name: "login-redirect___pt"
  }, {
    path: "/ro/account/profile",
    component: _122685ec,
    name: "account-profile___ro"
  }, {
    path: "/ro/login/callback",
    component: _599b2c96,
    name: "login-callback___ro"
  }, {
    path: "/ro/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___ro"
  }, {
    path: "/ro/login/redirect",
    component: _13a860ad,
    name: "login-redirect___ro"
  }, {
    path: "/ru/account/profile",
    component: _122685ec,
    name: "account-profile___ru"
  }, {
    path: "/ru/login/callback",
    component: _599b2c96,
    name: "login-callback___ru"
  }, {
    path: "/ru/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___ru"
  }, {
    path: "/ru/login/redirect",
    component: _13a860ad,
    name: "login-redirect___ru"
  }, {
    path: "/tr/account/profile",
    component: _122685ec,
    name: "account-profile___tr"
  }, {
    path: "/tr/login/callback",
    component: _599b2c96,
    name: "login-callback___tr"
  }, {
    path: "/tr/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___tr"
  }, {
    path: "/tr/login/redirect",
    component: _13a860ad,
    name: "login-redirect___tr"
  }, {
    path: "/ua/account/profile",
    component: _122685ec,
    name: "account-profile___ua"
  }, {
    path: "/ua/login/callback",
    component: _599b2c96,
    name: "login-callback___ua"
  }, {
    path: "/ua/login/confirm",
    component: _9fa76c02,
    name: "login-confirm___ua"
  }, {
    path: "/ua/login/redirect",
    component: _13a860ad,
    name: "login-redirect___ua"
  }, {
    path: "/",
    component: _c5fb4c92,
    name: "index___de___default"
  }],

  parseQuery: (s) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require('@/shared/utils/QueryParser').parse(s);
    },
  stringifyQuery: (o) => {
      if (!o) {
        return '';
      }
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const r = require('@/shared/utils/QueryParser').stringify(o);
      return r ? '?' + r : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
