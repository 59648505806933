import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faGoogle as freeFabFaGoogle,
  faYahoo as freeFabFaYahoo,
  faAndroid as freeFabFaAndroid,
  faApple as freeFabFaApple,
  faFacebookSquare as freeFabFaFacebookSquare,
  faInstagramSquare as freeFabFaInstagramSquare,
  faLinkedin as freeFabFaLinkedin,
  faWhatsapp as freeFabFaWhatsapp,
  faTelegram as freeFabFaTelegram
} from '@fortawesome/free-brands-svg-icons'

import {
  faMoon as proFasFaMoon,
  faSunBright as proFasFaSunBright,
  faHeart as proFasFaHeart,
  faStar as proFasFaStar,
  faGripVertical as proFasFaGripVertical
} from '@fortawesome/pro-solid-svg-icons'

import {
  faLock as proFarFaLock,
  faLockOpen as proFarFaLockOpen,
  faCog as proFarFaCog,
  faBuilding as proFarFaBuilding,
  faExternalLinkSquare as proFarFaExternalLinkSquare,
  faBookOpen as proFarFaBookOpen,
  faEye as proFarFaEye,
  faTimes as proFarFaTimes,
  faExternalLink as proFarFaExternalLink,
  faSpinnerThird as proFarFaSpinnerThird,
  faAngleDown as proFarFaAngleDown,
  faAngleUp as proFarFaAngleUp,
  faArrowLeft as proFarFaArrowLeft,
  faArrowRight as proFarFaArrowRight,
  faClock as proFarFaClock,
  faTag as proFarFaTag,
  faChevronUp as proFarFaChevronUp,
  faGear as proFarFaGear,
  faAngleLeft as proFarFaAngleLeft,
  faAngleRight as proFarFaAngleRight,
  faCheck as proFarFaCheck,
  faStar as proFarFaStar,
  faSignOut as proFarFaSignOut,
  faCircleInfo as proFarFaCircleInfo,
  faCheckCircle as proFarFaCheckCircle,
  faUserEdit as proFarFaUserEdit,
  faPlus as proFarFaPlus,
  faChevronDown as proFarFaChevronDown,
  faInfoSquare as proFarFaInfoSquare,
  faCopy as proFarFaCopy,
  faMapMarkedAlt as proFarFaMapMarkedAlt,
  faUserTimes as proFarFaUserTimes,
  faMoon as proFarFaMoon,
  faSunBright as proFarFaSunBright,
  faSearch as proFarFaSearch,
  faUserMagnifyingGlass as proFarFaUserMagnifyingGlass,
  faX as proFarFaX,
  faUserSecret as proFarFaUserSecret,
  faBadgeCheck as proFarFaBadgeCheck,
  faSlidersSimple as proFarFaSlidersSimple,
  faHeart as proFarFaHeart,
  faCalendarCheck as proFarFaCalendarCheck,
  faEllipsisH as proFarFaEllipsisH,
  faUser as proFarFaUser,
  faCalendarWeek as proFarFaCalendarWeek,
  faFilter as proFarFaFilter,
  faLocationDot as proFarFaLocationDot,
  faMap as proFarFaMap,
  faMinus as proFarFaMinus,
  faBan as proFarFaBan,
  faShieldCheck as proFarFaShieldCheck,
  faArrowUpRightFromSquare as proFarFaArrowUpRightFromSquare,
  faHandPaper as proFarFaHandPaper,
  faListAlt as proFarFaListAlt,
  faUndo as proFarFaUndo,
  faBolt as proFarFaBolt,
  faSms as proFarFaSms,
  faCommentSlash as proFarFaCommentSlash,
  faPaperPlane as proFarFaPaperPlane,
  faRepeat as proFarFaRepeat,
  faFaceAwesome as proFarFaFaceAwesome,
  faArrowTurnDownLeft as proFarFaArrowTurnDownLeft,
  faDice as proFarFaDice,
  faDiceOne as proFarFaDiceOne,
  faDiceTwo as proFarFaDiceTwo,
  faDiceThree as proFarFaDiceThree,
  faDiceFour as proFarFaDiceFour,
  faDiceFive as proFarFaDiceFive,
  faDiceSix as proFarFaDiceSix,
  faHeartCircleCheck as proFarFaHeartCircleCheck,
  faListRadio as proFarFaListRadio,
  faEnvelope as proFarFaEnvelope,
  faComment as proFarFaComment
} from '@fortawesome/pro-regular-svg-icons'

import {
  faExclamationCircle as proFadFaExclamationCircle,
  faSpinnerThird as proFadFaSpinnerThird,
  faSearch as proFadFaSearch,
  faEnvelope as proFadFaEnvelope,
  faCalendarPlus as proFadFaCalendarPlus,
  faTableList as proFadFaTableList,
  faMap as proFadFaMap,
  faPencil as proFadFaPencil,
  faKeySkeleton as proFadFaKeySkeleton,
  faImages as proFadFaImages,
  faTrashAlt as proFadFaTrashAlt,
  faTimesCircle as proFadFaTimesCircle
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  freeFabFaGoogle,
  freeFabFaYahoo,
  freeFabFaAndroid,
  freeFabFaApple,
  freeFabFaFacebookSquare,
  freeFabFaInstagramSquare,
  freeFabFaLinkedin,
  freeFabFaWhatsapp,
  freeFabFaTelegram,
  proFasFaMoon,
  proFasFaSunBright,
  proFasFaHeart,
  proFasFaStar,
  proFasFaGripVertical,
  proFarFaLock,
  proFarFaLockOpen,
  proFarFaCog,
  proFarFaBuilding,
  proFarFaExternalLinkSquare,
  proFarFaBookOpen,
  proFarFaEye,
  proFarFaTimes,
  proFarFaExternalLink,
  proFarFaSpinnerThird,
  proFarFaAngleDown,
  proFarFaAngleUp,
  proFarFaArrowLeft,
  proFarFaArrowRight,
  proFarFaClock,
  proFarFaTag,
  proFarFaChevronUp,
  proFarFaGear,
  proFarFaAngleLeft,
  proFarFaAngleRight,
  proFarFaCheck,
  proFarFaStar,
  proFarFaSignOut,
  proFarFaCircleInfo,
  proFarFaCheckCircle,
  proFarFaUserEdit,
  proFarFaPlus,
  proFarFaChevronDown,
  proFarFaInfoSquare,
  proFarFaCopy,
  proFarFaMapMarkedAlt,
  proFarFaUserTimes,
  proFarFaMoon,
  proFarFaSunBright,
  proFarFaSearch,
  proFarFaUserMagnifyingGlass,
  proFarFaX,
  proFarFaUserSecret,
  proFarFaBadgeCheck,
  proFarFaSlidersSimple,
  proFarFaHeart,
  proFarFaCalendarCheck,
  proFarFaEllipsisH,
  proFarFaUser,
  proFarFaCalendarWeek,
  proFarFaFilter,
  proFarFaLocationDot,
  proFarFaMap,
  proFarFaMinus,
  proFarFaBan,
  proFarFaShieldCheck,
  proFarFaArrowUpRightFromSquare,
  proFarFaHandPaper,
  proFarFaListAlt,
  proFarFaUndo,
  proFarFaBolt,
  proFarFaSms,
  proFarFaCommentSlash,
  proFarFaPaperPlane,
  proFarFaRepeat,
  proFarFaFaceAwesome,
  proFarFaArrowTurnDownLeft,
  proFarFaDice,
  proFarFaDiceOne,
  proFarFaDiceTwo,
  proFarFaDiceThree,
  proFarFaDiceFour,
  proFarFaDiceFive,
  proFarFaDiceSix,
  proFarFaHeartCircleCheck,
  proFarFaListRadio,
  proFarFaEnvelope,
  proFarFaComment,
  proFadFaExclamationCircle,
  proFadFaSpinnerThird,
  proFadFaSearch,
  proFadFaEnvelope,
  proFadFaCalendarPlus,
  proFadFaTableList,
  proFadFaMap,
  proFadFaPencil,
  proFadFaKeySkeleton,
  proFadFaImages,
  proFadFaTrashAlt,
  proFadFaTimesCircle
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
