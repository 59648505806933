import {
  Attr,
  BelongsTo,
  HasMany,
  JsonApiService,
} from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { Address } from '@/shared/jsonapi-orm/common/Address'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import {
  CustomEntry,
  CustomEntryMap,
} from '@/shared/jsonapi-orm/custom-fields/CustomEntry'
import { ModelIdentifier } from '@/shared/jsonapi-orm/bookingbuddy/ModelIdentifier'
import { PlanSubscription } from '@/shared/jsonapi-orm/subscriptions/PlanSubscription'
import { ucFirst } from '@/shared/utils/StrHelpers'
import { DocumentAcceptance } from '@/shared/jsonapi-orm/bookingbuddy/DocumentAcceptance'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { CommunityInvite } from '@/shared/jsonapi-orm/bookingbuddy/CommunityInvite'

export type MobileNotificationOption = 'none' | 'sms' | 'whatsapp' | 'telegram'

interface CustomerSettings {
  notifications?: {
    telegram: boolean
    telegramId?: string | null
    sms: boolean
    whatsapp: boolean
  }
}

export enum SexType {
  UNKNOWN = 0,
  MALE = 1,
  FEMALE = 2,
  OTHER = 9,
}

export enum CustomerEmailStatus {
  UNKNOWN = 'unknown',
  VALID = 'valid',
  INVALID = 'invalid',
  STORAGE_EXCEEDED = 'storage_exceeded',
}

export class Customer extends ApiResource {
  static jsonApiType = 'customers'

  @Attr('uuid') uuid: string
  @Attr() number: string
  @Attr('de') locale: string
  @Attr(null) title: string | null
  @Attr(SexType.UNKNOWN) sex: SexType | null
  @Attr(null) birthDate: string | null
  @Attr('') givenName: string
  @Attr('') familyName: string
  @Attr() fullName: string
  @Attr('') email: string
  @Attr() emailStatus?: CustomerEmailStatus
  @Attr(null) mobile: string | null
  @Attr(null) phone: string | null
  @Attr(null) company: string | null
  @Attr({}) customEntryMap: CustomEntryMap
  @Attr() createdAt: string
  @Attr() settings: CustomerSettings | null

  @BelongsTo() address?: Address | null
  @HasMany() orders?: Order[]
  @HasMany() subscriptions?: PlanSubscription[]
  @HasMany() communities?: Community[]
  @HasMany() communityInvites?: CommunityInvite[]
  @HasMany() customEntries?: CustomEntry[]
  @HasMany() modelIdentifiers?: ModelIdentifier[]
  @HasMany() documentAcceptances?: DocumentAcceptance[]

  get mobileNotificationOption(): MobileNotificationOption {
    if (this.settings?.notifications) {
      if (this.settings.notifications.whatsapp) {
        return 'whatsapp'
      } else if (this.settings.notifications.telegram) {
        return 'telegram'
      } else if (this.settings.notifications.sms) {
        return 'sms'
      }
    }
    return 'none'
  }

  set mobileNotificationOption(value) {
    let notificationSettings: CustomerSettings['notifications'] = {
      sms: false,
      telegram: false,
      whatsapp: false,
    }
    switch (value) {
      case 'sms':
        notificationSettings = {
          sms: true,
          telegram: false,
          whatsapp: false,
        }
        break
      case 'telegram':
        notificationSettings = {
          sms: false,
          telegram: true,
          whatsapp: false,
        }
        break
      case 'whatsapp':
        notificationSettings = {
          sms: false,
          telegram: false,
          whatsapp: true,
        }
    }
    this.settings = {
      ...this.settings,
      notifications: {
        ...this.settings?.notifications,
        ...notificationSettings,
      },
    }
  }

  parseStringInput(input: string) {
    const emailRegEx = /\S+@\S+\.\S+/
    const isEmail: boolean = emailRegEx.test(input.toLowerCase())
    let names: string[] = []
    if (isEmail) {
      this.email = input.toLowerCase()
      const potentialName = input.substring(0, input.indexOf('@'))
      names = potentialName.split('.')
    } else {
      // full name expected
      names = input.split(' ')
    }
    if (names.length >= 1) {
      this.givenName = ucFirst(names[0].toLowerCase())
    }
    if (names.length >= 2) {
      this.familyName = names
        .slice(1)
        .map((str: string) => ucFirst(str.toLowerCase()))
        .join(' ')
    }
    this.fullName = this.givenName + ' ' + this.familyName
  }

  /**
   * Resolve customer from code.
   * @param apiService
   * @param code
   */
  static async resolveCode(
    apiService: JsonApiService,
    code: string
  ): Promise<Customer | null> {
    const { data } = await this.api(apiService)
      .query({ code })
      .request('resolve-code')
    return this.resourceFromResponse(data, apiService).data
  }

  getLetterHead() {
    if (this.address) {
      return `${this.givenName} ${this.familyName}\n${this.address.getAddressText()}`
    }
  }
}
