import { Context, Plugin } from '@nuxt/types'
import { SettingsManager } from '@/shared/services/SettingsManager'
import {
  createDefaultClient,
  getApiBaseUrl,
} from '@/shared/services/axiosClientFactory'
import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $customerAccountSettings: SettingsManager
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$customerAccount inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $customerAccountSettings: SettingsManager
  }
  // nuxtContext.$customerAccount
  interface Context {
    $customerAccountSettings: SettingsManager
  }
}

const plugin: Plugin = async function (ctx: Context, inject) {
  const basicClient = createDefaultClient(
    ctx,
    '',
    getApiBaseUrl(ctx),
    true,
    false
  )

  const customerAccountSettings = new SettingsManager(
    basicClient,
    ctx.store,
    ctx.$config.apiBaseUrl,
    'customer-account'
  )
  inject('customerAccountSettings', Vue.observable(customerAccountSettings))
}
export default plugin
