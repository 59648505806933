
import Vue from 'vue'
import BaseLayout from '@/shared/layouts/BaseLayout.vue'
import Spinner from '@/shared/components/partials/Spinner.vue'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
  components: {
    BaseLayout,
    Spinner,
  },
  middleware: ['browserSupport', 'preferredLocale', 'requiresAuth', 'settings'],
  data() {
    return {
      platform: this.$store.getters['platform/platform'](
        this.$jsonApiService
      ) as Platform | null,
      mounting: true,
    }
  },
  head(): MetaInfo {
    const svgFaviconUrl = this.platform?.config.svgFaviconUrl
    const platformName = this.platform?.name ?? 'anny'
    let links: MetaInfo['link'] = []
    if (this.platform) {
      links = svgFaviconUrl
        ? [
            {
              rel: 'icon',
              type: 'image/svg+xm',
              href: svgFaviconUrl,
              sizes: 'any',
            },
          ]
        : []
    } else {
      links = [
        {
          rel: 'apple-touch-startup-image',
          hid: 'apple-touch-startup-image',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'apple-touch-icon',
          hid: 'apple-touch-icon',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'shortcut icon',
          href: '_favicons/favicon.ico',
          hid: 'shortcut icon',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-16x16.png',
          sizes: '16x16',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-32x32.png',
          sizes: '32x32',
        },
      ]
    }

    return {
      title: platformName,
      titleTemplate: `%s | ${platformName}`,
      link: links,
    }
  },
  computed: {
    customStyles(): string | null {
      const platformStyles = this.platform?.config?.styles ?? null
      const customStyles = this.$store.getters['ux/getCustomGlobalCss']
      if (!platformStyles && !customStyles) {
        return null
      }
      let css = ''
      if (platformStyles) {
        css += platformStyles
      }
      if (customStyles) {
        css += customStyles
      }
      return css
    },
  },
  async mounted() {
    this.mounting = false
  },
})
